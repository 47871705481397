import './Conclusion.css';

export default function Conclusion() {
    return (
        <div className="Conclusion-container">
            <div className="Conclusion-item">
                <h2>Conclusion</h2>
            </div>
        </div>
    );
}
