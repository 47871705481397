import React, { Component } from 'react';
import Game from '../../components/Game/Game.js';
import { Link } from 'react-router-dom';
import './Activity.css';

export default function Activity(props) {
    if (props.mode == "group") {
        return (
            <div className="Activity-container">
                <div className="Activity-top">
                </div>
                <div className="Activity-item Activity-game">
                    <Game mode="group" size="12" />
                </div>
            </div>
        );
    } else if (props.mode == "single") {
        return (
            <div className="Activity-container">
                <div className="Activity-top">
                    <h1>Sweetgrass Activity</h1>
                </div>
                <div className="Activity-item Activity-game">
                    <Game mode="group" size="12" />
                </div>
            </div>
        );
    }
}