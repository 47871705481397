import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import './App.css';
import Intro from './routes/Intro/Intro.js';
import Activity from './routes/Activity/Activity.js';
import Conclusion from './routes/Conclusion/Conclusion.js';

export default function App() {
  return (
    <Router>
        <Routes>
          <Route path='/' element={<Intro />} />
          <Route path='/activity' element={<Activity mode="group" />} />
          <Route path='/conclusion' element={<Conclusion />} />
        </Routes>
    </Router>
  );
}
