import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Game.css';
import Card from '../../components/Card/Card.js';
import cardsdata from '../../data/cards-data.json';

function Game(props) {
    const gamemode = props.mode;
    const handsize = parseInt(props.size);

    // States
    const [hand, setHand] = useState([]);
    const [button, setButton] = useState("Next");
    

    // Refs
    const deck = useRef(cardsdata.slice());
    const allflipped = useRef(true);
    const selectedCard = useRef(null);

    function drawCards() {
        let newhand = Array(handsize).fill(null);

        // We check how many cards are left in the deck, if not enough are left, we reset the deck
        if (deck.current.length < handsize) {
            deck.current = cardsdata.slice();
            setButton("Next");
        } else if ((deck.current.length - handsize) <= 0) {
            setButton("Restart");
        }

        // We draw [handsize] number of cards randomly from the deck
        for (let i = 0; i < handsize; i++) {
            let rng = Math.floor(Math.random() * deck.current.length);
            newhand[i] = deck.current.splice(rng, 1)[0];
            newhand[i].flipped = null; // Default is "null" so we don't trigger flipped animation the first time
        }
        selectedCard.current = null;
        allflipped.current = true;
        setHand(newhand);
    }

    // A card has been clicked, trigger animation in card element
    function clickCard(i) {
        //console.log(i);
        if (!allflipped.current) {
            flipAllCards();
            return;
        }
        let newhand = hand.map((card, id) => {
            if (id == i) {
                return { ...card, flipped: (card.flipped === null) ? false : !card.flipped };
            }
            else if (card.flipped === null || card.flipped) {
                return { ...card, flipped: null };
            }
            else {
                return { ...card, flipped: true };
            }
        });
        if (selectedCard.current === null || selectedCard.current.index != newhand[i].index) {
            selectedCard.current = newhand[i];
        }
        else {
            selectedCard.current = null;
        }
        setHand(newhand);
    }

    // Flipp all the cards at once
    function flipAllCards() {
        let newhand = hand.map((card) => {
            return { ...card, flipped: !allflipped.current };
        });
        allflipped.current = !allflipped.current;
        console.log(allflipped.current);
        selectedCard.current = null;
        setHand(newhand);
    }

    // Play audio file
    function playAudio() {
        let url = "https://firebasestorage.googleapis.com/v0/b/sweetgrass-eb73b.appspot.com/o/audio%2F" + selectedCard.current.audio + "?alt=media";
        let audio = new Audio(url);
        //let newhand = hand.slice();
        //newhand[i].shine = newhand[i].anishinaabe;
        //setHand(newhand);
        audio.play();
    }

    // Draw cards automatically the first time
    useEffect(() => {
        drawCards();
    }, []);

    // Cards appearing animation
    function Gameboard() {
        if (hand.length > 0) {
            return (
                hand.map((card, i) => {
                    //console.log(i);
                    return (
                            <Card
                            card={card}
                            key={card.index}
                            onClick={() => clickCard(i)}
                            />
                    )
                })
            );
        } else {
            return "Loading...";
        }
    }

    function TopDisplay() {
        if (selectedCard.current === null) {
            return (
                <h2>Sometimes, people say that I am...</h2>
            );
        } else {
            return (
                <h2>Sometimes, people say that I am <span className="Text-rough">{selectedCard.current.rough}</span>,
                but really I am <span className="Text-shine">{selectedCard.current.shine}</span> [
                <a className="Audio-link" onClick={() => { playAudio(); }}>{selectedCard.current.anishinaabe}<div className="Img-speaker" title="Play Audio"></div></a>].</h2>
            );
        }
    }    
        
    return (
        <div className="Game-container">
            <div className="Game-top">
                <TopDisplay />
            </div>
            <div className="Game-display">
                <Gameboard />
            </div>
            <div className="Game-bottom">
                <div className="Game-bottom-side"></div>
                <div className="Game-bottom-center">
                    <button className="Menu-button Button-orange" onClick={() => flipAllCards()}><div className="Menu-icon Img-flip" title="Flip All"></div></button>
                    <button className="Menu-button Button-orange" onClick={() => drawCards()}><div className="Menu-icon Img-draw" title="Draw"></div></button>
                </div>
                <div className="Game-bottom-side">
                    <Link to="/"><button className="Menu-button Button-blue"><div className="Menu-icon Img-exit" title="Exit"></div></button></Link>
                </div>
            </div>
        </div>
    );
}

export default Game;