import React, { Component } from 'react';
import './Card.css';

class Card extends Component {
    cardFlip() {
        // flipped is null if card is displayed for the first time, so no animation
        if (this.props.card.flipped === null) {
            return "card_wrapper";
        } else if (this.props.card.flipped) {
            return "card_wrapper anim_reverse";
        } else {
            return "card_wrapper anim_forward";
        }
    }

    render() {
        return (
            <div className="card_box" >
                <div className={this.cardFlip()} onClick={() => this.props.onClick()}>
                    <div className="card_face">
                        <span className="align-center">{this.props.card.rough}</span>
                    </div>
                    <div className="card_face c_back">
                        <span className="align-center">{this.props.card.shine}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Card;