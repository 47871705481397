import { Link } from 'react-router-dom';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import './Intro.css';

export default function Intro() {
    const alignCenter = { display: 'flex', alignItems: 'center' };

    const bgColors = {
        blue: '#457572', orange: '#ca6741', pale: '#e9ddc3', bgblue: '#177191', darkblue: '#010405', white: '#ffffff'
    };
    //<ParallaxLayer offset={0} speed={0.5} style={{ backgroundColor: bgColors.blue}} />
    return (
        <div className="Intro-container">
            <Parallax pages={7} config={{ mass: 0, tension: 280, friction: 20 }}>
                <ParallaxLayer sticky={{ start: 0, end: 1 }} speed={-1} style={{ backgroundColor: bgColors.bgblue, zIndex: -1}}>
                    <div className="Title-layer Img-layer1">
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0} factor={0.3} speed={-0.95} style={{  }}>
                    <div className="Intro-title">
                        <h1>the sweetgrass cards</h1>
                        <p>a Biskaabiiyaang journey</p>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0} speed={-0.95} style={{ }}>
                    <div className="Title-layer Img-layer2">
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0} speed={-0.9} style={{ }}>
                    <div className="Title-layer Img-layer3">
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0} speed={-0.8} style={{ }}>
                    <div className="Title-layer Img-layer4">
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0} speed={-0.7} style={{ }}>
                    <div className="Title-layer Img-layer5">
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0} speed={-0.5} style={{}}>
                    <div className="Title-layer Img-layer6">
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0} speed={-0.4} style={{ }}>
                    <div className="Title-layer Img-layer7">
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0} speed={-0.25} style={{ }}>
                    <div className="Title-layer Img-layer8">
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0.05} factor={1.1} speed={0} style={{ }}>
                    <div className="Title-layer Img-layer9">
                    </div>
                </ParallaxLayer>
                
                <ParallaxLayer offset={1} speed={0} style={{ ...alignCenter, backgroundColor: bgColors.darkblue }}>
                    <div className="Parallax-layer">
                        <div className="Intro-kindness">
                            <h1>kindness</h1>
                            <p>In Anishinaabe teachings, Kindness comes first.</p>
                            <p>Kindness is all around us: the sacrifices made by those beings that give us sustenance, waters that give us life, our Clans that gift us with our life's purpose, rites of passage that raise us through each stage of life, traditional ways of teaching and learning, and how we structure our understanding of community. Kindness is in every gift we are given to achieve the Good Life.</p>
                        </div>
                    
                        <div className="Intro-kindness">
                            <h1>sweetgrass, kindness medicine</h1>
                            <p>Sweetgrass is a Kindness medicine. There are many teachings about the power of Sweetgrass, and this activity represents only one.</p>
                            <p>When picking Sweetgrass, you notice the uppermost side is shiny while the underside is rough. Well, that's us! We all have our "shiny" and our "rough" side. Like Sweetgrass, we are taught to display only our shiny sides, never the rough. Sometimes, this is difficult, so we must work hard to become Sweetgrass Warriors! A Sweetgrass Warrior fights lateral violence with daily acts of lateral Kindness, they shield and protect their community and kin from the rough. </p>
                        </div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer offset={2} factor={4} speed={0.0} style={{ ...alignCenter, backgroundColor: bgColors.blue }}>
                </ParallaxLayer>

                <ParallaxLayer sticky={{ start: 2, end: 5 }} speed={0.0} style={alignCenter}>
                    <div className="Intro-warrior">
                        <h1>the path</h1><h1>of the</h1><h1>sweetgrass warrior</h1>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={2} speed={0.0} style={{ ...alignCenter, justifyContent: 'flex-end' }}>
                    <div className="Intro-warrior-content">
                        <p>When we learn to shield our thoughts from being rough and unkind, we can become Sweetgrass Warriors.</p>
                        <p>Every personality trait can be described as both shiny and rough.</p>
                        <p>It is a difficult yet necessary task to learn to flip the rough to its shiny equivalent in everything we think and feel.</p>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={3} speed={0.0} style={{ ...alignCenter, justifyContent: 'flex-end' }}>
                    <div className="Intro-warrior-content">
                        <p>The first steps involve being kind to ourselves. The activity linked below asks you to choose a card featuring a rough word you use to describe yourself. Clicking the card will flip it, replacing the rough word with its shiny version.</p>
                        <p>Bringing Kindness into the equation helps us alter our understanding. It makes us kinder to ourselves in our actions, thoughts, and feelings towards our personality traits.</p>
                        <p>This is not to say we should not grow and change, but thinking and feeling with Kindness helps us strive to do better.</p>
                        <p>Try doing the same with words others have used to describe you, too.</p>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={4} speed={0.0} style={{ ...alignCenter, justifyContent: 'flex-end' }}>
                    <div className="Intro-warrior-content">
                        <p>We must also learn to be kind to others.</p>
                        <p>Try picking a word to describe someone with a personality trait you dislike. When the card flips, does it change the way you think about that person? When your perception of a person changes, your behavior towards them changes also.</p>
                        <p>Performing Kindness in your thoughts creates compassion, changes the energy in your interactions, and allows for deeper connections. Then, our social environment starts to change too. If we think, feel, speak, and write about each other with Kindness, we promote kinder ways of being toward one another.</p>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer sticky={{ start: 5, end: 5 }} speed={0.0} style={{ ...alignCenter, justifyContent: 'flex-end'}}>
                    <div className="Intro-warrior-content Intro-warrior-button">
                        <Link to="/activity"><button className="Intro-menu-button">Start your Journey</button></Link>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer offset={6} factor={0.8} speed={0.0} style={{ ...alignCenter, backgroundColor: bgColors.pale }}>
                    <div className="Intro-about">
                        <h1>about</h1>
                        <p>This Sweetgrass Cards activity is part of the <a href="https://www.nokiiwin.com/kindness-training">Spirit Builder training</a> developed by Odehamik Maya Chacaby (Anishinaabe), Waabishka Miigwan Stephanie Stephens (Anishinaabe), and Nokiiwin Tribal Council.</p>
                        <p>Anishinaabemowin (Ojibwe language) translations done by Mary Blakely (Animbiigoo Zaagi'igan Anishinaabek) and Ma-Nee Chacaby (Animbiigoo Zaagi' igan Anishinaabek).</p>
                        <p>A physical deck of Sweetgrass Cards can be ordered directly <a href="mailto:executiveassistant@nokiiwin.com?subject=Sweetgrass%20Cards">from Nokiiwin</a>.</p>
                        <p>The digitizing of the cards is part of the Biskaabiiyaang project. Learn more at <a href="https://www.biskaabiiyaang.com">biskaabiiyaang.com</a>.</p>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer offset={6.8} factor={0.2} speed={0.0} style={{ ...alignCenter, backgroundColor: bgColors.white }}>
                    <div className="Intro-logos">
                        <a href="https://www.nokiiwin.com/"><div className="Img-logo Logo1" title="Nokiiwin"></div></a>
                        <a href="https://www.canada.ca/en/canadian-heritage.html"><div className="Img-logo Logo4" title="Canada Heritage"></div></a>
                        <a href="https://www.biskaabiiyaang.com/"><div className="Img-logo Logo2" title="Biskaabiiyaang"></div></a>
                        <a href="https://www.univirtual.com/"><div className="Img-logo Logo3" title="UniVirtual"></div></a>
                    </div>
                </ParallaxLayer>
            </Parallax>
        </div>
    );
}